@media only screen and (max-width : 480px){
  .Sidebar {
    flex-direction: row !important;
    height: 5% !important;
    width: 118%  !important;
    justify-content: space-around !important;
    padding: 10px 0 !important;
  }

  .topImage {
    height: 100% !important;
    margin-top: 2px !important;
    margin-left: -92px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px){
  .Sidebar {
    flex-direction: row !important;
    height: 4% !important;
    width: 100%  !important;
    justify-content: space-around !important;
    padding: 10px 0 !important;
  }

  .topImage {
    height: 100% !important;
    margin-top: 2px !important;
    margin-left: 32px !important;
    width: 100% !important;
  }
}


.Sidebar {
    bottom: 0;
    box-shadow: 0 2px 8px rgba(0,0,0,.25);
    position: absolute;
    top: 0;
    width: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
 
}

.topImage{
  padding: 1rem !important;
  margin-top: 32px;
}

.topImage img{
  vertical-align: middle;
}

/* .MenuList {

} */
.MenuList .MenuItem {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}
.MenuItem:hover{
  background-color: #c2e7ff;
}

.MenuItem:hover > svg{
  fill: #29383e;
}
.MenuItem svg{
  fill:rgb(157 152 152)
}
.activeMenuItem{
  background: #c2e7ff;
  color:'white'

}
.activeMenuItem svg{
  fill: #29383e;
}