.container1 {
  width: 480px;
  padding: 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  min-height: 430px;
  position: relative;
}

.table-head {
  margin-bottom: 10px;
  text-align: left;
}

.header-line {
  border-top: 2px solid #e1e1e1;
  margin: 0 0 20px 0;
}

/* Remove borders for the ag-Grid table */
.ag-theme-alpine .ag-header,
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-row {
  border: none !important;
}

/* Make the first column bold */
.ag-theme-alpine .ag-row .ag-cell:first-child {
  font-weight: 600;
  color: var(--Neutral-900, #2C2C30);
}

/* Adjust table header styles */
.ag-theme-alpine .ag-header-cell {
  background-color: #fff;
  font-weight: 600;
  color: #2C2C30;
}


/* Default styles will remain unchanged */

/* For large screens (2000px and above) */
@media only screen and (min-width: 2000px) {
  .container1 {
    width: 720px;            /* Increase width */
    padding: 30px;           /* Increase padding */
    background-color: #fff;
    border-radius: 24px;      /* Increase border radius */
    box-shadow: 0px 3px 18px 0px rgba(13, 10, 44, 0.25); /* Stronger shadow */
    font-family: 'Noto Sans', sans-serif;
    font-size: 21px;         /* Increase font size */
    min-height: 645px;       /* Increase height */
  }

  .table-head {
    margin-bottom: 15px;
    text-align: left;
  }

  .header-line {
    border-top: 3px solid #e1e1e1;  /* Thicker border */
    margin: 0 0 30px 0;             /* Increase margin */
  }

  /* ag-Grid table */
  .ag-theme-alpine .ag-header,
  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-row,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-row {
    border: none !important;
  }

  /* Make the first column bold */
  .ag-theme-alpine .ag-row .ag-cell:first-child {
    font-weight: 600;
    color: var(--Neutral-900, #2C2C30);
    font-size: 21px;       /* Increase font size for first column */
  }

  /* Adjust table header styles */
  .ag-theme-alpine .ag-header-cell {
    background-color: #fff;
    font-weight: 600;
    color: #2C2C30;
    font-size: 21px;       /* Increase font size for header */
  }
}

/* For small screens (768px and below) */
@media only screen and (max-width: 768px) {
  .container1 {
    width: 320px;            /* Decrease width */
    padding: 10px;           /* Decrease padding */
    background-color: #fff;
    border-radius: 12px;      /* Decrease border radius */
    box-shadow: 0px 1px 8px 0px rgba(13, 10, 44, 0.10); /* Lighter shadow */
    font-family: 'Noto Sans', sans-serif;
    font-size: 10px;         /* Decrease font size */
    min-height: 215px;       /* Decrease height */
  }

  .table-head {
    margin-bottom: 5px;
    text-align: left;
  }

  .header-line {
    border-top: 1px solid #e1e1e1;  /* Thinner border */
    margin: 0 0 10px 0;             /* Decrease margin */
  }

  /* ag-Grid table */
  .ag-theme-alpine .ag-header,
  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-row,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-row {
    border: none !important;
  }

  /* Make the first column bold */
  .ag-theme-alpine .ag-row .ag-cell:first-child {
    font-weight: 600;
    color: var(--Neutral-900, #2C2C30);
    font-size: 10px;       /* Decrease font size for first column */
  }

  /* Adjust table header styles */
  .ag-theme-alpine .ag-header-cell {
    background-color: #fff;
    font-weight: 600;
    color: #2C2C30;
    font-size: 10px;       /* Decrease font size for header */
  }
}
