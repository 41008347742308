@media only screen and (max-width : 480px){
  .gauge-svg{
    width: 125% !important;
    height: 328px !important;
    margin-left: 10px !important;
  }

  }


.gauge-svg{
    height: 500px;
    width: 100%;
    margin-left: 1px;
}
.meterContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.topin{
  width: 100%;
}
.number{
    font-size: 20px !important;
    color: black;
}
.text-div{
  position: relative;
  text-align: center;
  margin-top: -90px;
}
.header-line1 {
    border-top: 2px solid #e1e1e1;
    margin-top: -10px;
    margin-bottom: 50px;
    width: 100%;
    position: relative;
}
.gaugemeter{
    justify-content: center;
    align-items: center;
}
.topside{
    display: flex;
    width: 100%;
    justify-content: space-between;
}.svg-container {
    position: relative;
    display: inline-block;
  }
  
  .popup {
    display: none;
    position: absolute;
    top: 0%;
    left: -2500%;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    height: 320px;
    width: 480px;
  }
  
  .svg-container:hover .popup {
    display: block;
  }
  .piedes{
    color: #0A8394;
    font-size: 18px;
  }
  
.score-text{
  font-size: 40px
  
}