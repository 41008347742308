@media only screen and (max-width : 480px) {
  .container{
    width: 100% !important;
  }
}

.container {
    display: flex;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    height: 300px;
    width: 500px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
  }

  .ag-grid-container {
    overflow-x: auto; /* Allow horizontal scrolling */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
  }
  
  /* For Webkit browsers (Chrome, Safari) */
  .ag-grid-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
  

  .bold-row .ag-cell {
    font-weight: bold;
  }
  

  .state-abbreviation-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .state-abbreviation {
    background-color: yellow;
    padding: 10px;
    margin: 5px;
    font-weight: bold;
    border-radius: 5px;
  }
  