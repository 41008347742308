@media only screen and (max-width : 480px){

  .main-content{
    margin-left: 0 !important;
    width: 100% !important;
    padding: 0px 40px 0px 40px !important;
  }
  .sidebar-main{
    position: static !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px){

  .main-content{
    margin-left: 0 !important;
    width: 94% !important;
    padding: 0px 24px !important;
    margin-top: 50px !important;
  }
  .sidebar-main{
    position: static !important;
  }
}

.main{
  margin-left: 100px;
  /* background: #FAFAFA; */
}

.main-content{
  margin-left: 80px;
  margin-top: 0;
  background: #FFFFFF;
}

/* .App{
  display: flex;
  gap: 50px;
  justify-content: space-between;
} */

.sidebar-main{
  position: fixed;
}
